<template>
  <LxpMobileHeader :use-route-name="true">
    <template v-slot:left>
      <div class="util util-more">
        <a href="javascript:" class="util-actions util-actions-search" @click="headerToggle">
          <i class="icon-more"></i>
        </a>
        <div class="dropdown" :class="{'is-active': hToggle}" data-offset="header-left">
          <div class="dropdown-target">
            <div class="dropdown-box">
              <ul class="dropdown-option-list">
                <li class="dropdown-option-item">
                  <file-down-link :file-key="guideFileKey" :add-link-class="['dropdown-option-link']" />
                </li>
                <li class="dropdown-option-item">
                  <file-down-link :file-key="mngrFileKey" :add-link-class="['dropdown-option-link']" />
                </li>
                <li class="dropdown-option-item">
                  <a href="javascript:" class="dropdown-option-link" @click="goTest">사전테스트<i class="icon-arrow-next"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
        <div class="util util-download">
          <a href="javascript:" @click="showAttachments= true" class="util-actions util-actions-download">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
    </template>

  </LxpMobileHeader>
  <main class="kb-main" id="kb-educourse">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="educourse-view-container mb-0">
        <div class="view-contents">
          <!-- content-section:과정안내 -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">연수현황</h4>
            </header>
            <div class="text-item-container">
              <div class="content-item">
                <div class="title mb-2">필수희망 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <strong class="td-text"><span class="text-muted">
                  {{ dtlItem && dtlItem.reqYn === 'Y' ? '필수' : '희망' }}</span></strong></div>
                <div class="title mb-2">부점구분 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <strong class="td-text"><span class="text-muted">
                  {{ dtlItem && dtlItem.digiDeptTyNm ? dtlItem.digiDeptTyNm : '-' }}</span></strong></div>
                <div class="title mb-2">기본대상 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <template v-if="dtlItem && dtlItem.basicYn">
                    <strong class="td-text" v-html="getDigitalFinishText('trgt',dtlItem.basicYn)"></strong>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="title mb-2">기본완료 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <template v-if="basicLrn != '-'">
                    <strong class="td-text" v-html="getDigitalFinishText('fnsh',basicLrn)"></strong>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="title mb-2">심화대상 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <template v-if="dtlItem && dtlItem.advnYn">
                    <strong v-html="getDigitalFinishText('trgt',dtlItem.advnYn)"></strong>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="title mb-2">심화완료 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <template v-if="advanLrn != '-'">
                    <strong v-html="getDigitalFinishText('fnsh',advanLrn)"></strong>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="title mb-2">최종수료 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <template v-if="finalLrn != '-'">
                    <strong v-html="getDigitalFinishText('fnsh',finalLrn)"></strong>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </div>
              <div class="content-item"></div>
            </div>
            <div class="d-flex justify-content-center mb-2">
              <p class="text-muted" style="font-size: 12px; margin-top: -25px">※개인별 수료여부는 각 회차 종료 후 약 10영업일 후에 반영될 예정입니다.</p>
            </div>
          </section>
        </div>
      </div>
      <!-- content-section:진행중 연수 -->
      <template v-if="isReady">
        <div v-for="(item, index) in renderItems" :class="index > 0 ? 'mt-5' : ''" :key="index">
          <article class="content-section" style="padding: 10px 10px 0px 16px">
            <header class="section-header">
              <h4 class="title">사전과정<span v-if="items.length > 0" class="count">{{ items.length }}</span></h4>
            </header>
          </article>
            <div class="educourse-list-container">
              <!-- educourse-list -->

              <div v-if="items.length === 0" class="search-container">
                <div class="result-empty">
                  <img src="@/assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">{{descTitle}} 연수가 없습니다.</p>
                </div>
              </div>
              <ul v-else class="educourse-list">
                <!-- educourse-list > list-item -->
                <li v-for="(item, index) in items" class="list-item" :key="index">
                  <!-- educourse-list > educourse -->
                  <div class="educourse">
                    <div class="educourse-summary course-row">
                      <article class="course-card">
                        <div class="course-image">
                          <a href="javascript:" class="image-link" @click="goLearn(item)"></a>
                          <figure class="image-figure">
                            <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
                            <img v-else-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                            <CourseThumb v-else
                                         :num="item.crseMstSn"
                            />
                          </figure>
                        </div>
                        <div class="course-content">
                          <div class="content-header">
                            <h5 class="title">
                              <a href="javascript:" class="title-link" @click="goLearn(item)">{{ item.crseNm }}</a>
                            </h5>
                          </div>
                          <div class="content-footer">
                            <div class="content-meta">
                              <p class="text">{{  item.orgnzNm }}</p>
                              <p class="text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd')}} - {{timestampToDateFormat(item.endDt, 'yyyy.MM.dd')}}</p>
                            </div>
                            <div class="content-mileage">
                              <!--                      <p v-if="item.splCrseTyCdDcd === '2066015'"  class="text">-</p>-->
                              <p v-if="item.splCrseTyCdDcd === '2066023'" class="text" v-html="getFinishText(item)"></p>
                            </div>
                          </div>
                        </div>
                        <div class="course-actions">&nbsp;</div>
                      </article>
                    </div>
                  </div>
                </li>
                <!-- educourse-list > list-item -->
              </ul>

            </div>
        </div>
      </template>


      <template v-if="aplyItems.length > 0">
        <article class="content-section" style="padding: 30px 10px 0px 16px">
          <header class="section-header">
            <h4 class="title">연수과정<span v-if="aplyItems.length > 0" class="count">{{ aplyItems.length }}</span></h4>
          </header>
        </article>
        <div class="educourse-list-container">
          <ul class="educourse-list">
            <li v-for="(item, index) in aplyItems" :key="index" class="list-item">
              <div class="educourse">
                <div class="educourse-summary course-row" style="padding-bottom: 10px;cursor: pointer"
                     @click="collapseOrExpand(index)">
                  <article class="course-card">
                    <div class="course-image">
                      <figure class="image-figure">
                        <img v-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                        <CourseThumb
                            v-else
                            :num="item.crseMstSn"
                        />
                      </figure>
                    </div>
                    <div class="course-content">
                      <div class="content-header">
                        <h5 class="title">
                          {{ item.crseMstNm }}
                        </h5>
                      </div>
                      <div :class="{'content-footer': isMobile}">
                        <div class="content-meta">
                          <span class="text" :class="{'txt_line' : !isMobile, 'txt_multi' : isMobile}" style="color:var(--kb-gold)">{{ item.crseSumup }}</span>
                        </div>
                        <div class="content-meta" style="margin-top: 20px">
                          <span class="text">
                            {{ item.orgnzNm }}
                            {{ item.mstTotLrnPerid ? ' | '.concat(item.mstTotLrnPerid,'일') : '' }}
                            {{ item.crseMstMlg ? ' | 마일리지'.concat(item.crseMstMlg) : '' }}
                          </span>
                        </div>
                        <div v-if="isMobile" class="striped-list-wrap">
                          <ul class="striped-list">
                            <li class="striped-item">
                              <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                                <div class="kb-collapse-toggle striped-row" style="background-color: white">
                                  <div class="striped-column column-arrow" style="padding: 0px 0px;min-height: 0px"><i class="icon-arrow"></i></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>

                  <template v-if="showDists[index]">
                    <template v-if="item.crseDists.length > 0">
                      <template v-for="(dist, idx) in item.crseDists" :key="idx">
                        <template v-if="isShowApply(dist)">
                          <div class="educourse-content" style="border: 0px">
                            <div class="educourse-summary">
                              <p class="title">{{ dist.crseNm }}</p>
                              <p class="status text-red">
                                <span>
                                  정원 {{dist.peoplCnt > 0 ?
                                    `${numberComma(dist.peoplCnt)}명` :
                                    '-'}}
                                </span>
                                <span v-if="limitOver(dist)"> ・ 정원초과</span>
                              </p>
                            </div>
                            <div class="educourse-date">
                              <div class="date-item">
                                <div class="title">연수기간</div>
                                <div>
                                  {{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd') }} -
                                  {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd') }}
                                </div>
                              </div>
                              <div class="date-item">
                                <div class="title">신청기간</div>
                                <div>
                                  {{ timestampToDateFormat(dist.aplyBgngDt, 'yyyy.MM.dd') }} -
                                  {{ timestampToDateFormat(dist.aplyEndDt, 'yyyy.MM.dd') }}
                                </div>
                              </div>
                            </div>
                            <div class="educourse-actions">
                              <button class="kb-btn-apply" @click="showApplyViewMobile(dist.distCrseSn)">연수신청</button>
                            </div>
                          </div>
                        </template>
                      </template>
                    </template>
                  </template>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <!-- //main-content -->
  </main>
  <MobileTrainDigitalFileAtch v-if="showAttachments" v-model:showAttachments="showAttachments" :fileItems="files"/>
</template>

<script>

import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {trainDigitalSetup} from '@/assets/js/modules/train/train-digital-setup';
import CourseThumb from '@/components/apply/CourseThumb.vue';
import MobileTrainDigitalFileAtch from "@/views/pages/train/mobile/MobileTrainDigitalFileAtch";
import FileDownLink from "@/components/common/FileDownLink";

export default {
  name: 'MobileTrainDigital',
  components: {LxpMobileHeader, CourseThumb, MobileTrainDigitalFileAtch, FileDownLink},
  setup: trainDigitalSetup
}
</script>

<style scoped>


</style>